import $$ from 'dom7';
export default {
  data: function () {
    var host = this.$app.data.host;
    return {
      host: host,
      stateIcon01: `${host}/images/state01.svg`,
      stateIcon02: `${host}/images/state02.svg`,
      stateIcon03: `${host}/images/state03.svg`,
      storage: undefined,
      frontImageURI: undefined,
      sideImageURI: undefined,
      estimationParameter: undefined,
      requestInput: undefined,
      height: undefined,
      weight: undefined,
      age: undefined,
      gender: undefined
    };
  },
  methods: {
    getImageUriFromStorage: function () {
      const frontImageURI = sessionStorage.getItem('bgFrontImageURI');
      const sideImageURI = sessionStorage.getItem('bgSideImageURI');

      if (!frontImageURI || !sideImageURI) {
        throw new Error('imageURIが設定されていません');
      }

      return {
        frontImageURI,
        sideImageURI
      };
    }
  },
  on: {
    pageInit: function (e, page) {
      var app = page.app;
      var requestInput = JSON.parse(sessionStorage.getItem('bodyData'));
      this.height = requestInput.height / 10;
      this.weight = requestInput.weight / 1000;
      this.age = requestInput.age;
      this.gender = requestInput.gender === 'male' ? '男性' : '女性';
      var toastSuccess = app.toast.create({
        icon: `<i class="f7-icons">smiley</i>`,
        text: "測定に成功しました",
        position: 'center',
        closeTimeout: 3000,
        on: {
          close: function () {
            app.views.current.router.navigate('/result/');
          }
        }
      });
      var toastError = app.toast.create({
        icon: `<i class="f7-icons">xmark_seal</i>`,
        text: "測定に失敗しました",
        position: 'center',
        closeTimeout: 3000,
        on: {
          close: function () {
            sessionStorage.removeItem('openTutorial');
            app.dialog.alert("入力内容を確認し、ヘルプに従って撮影し直してください", function () {
              app.views.current.router.navigate('/form/');
            });
          }
        }
      });
      var data = {
        action: 'get_estimation_estimation_id',
        order_id: app.order_id,
        estimation_id: app.estimation_id
      };
      var count = 0;

      var countup = function () {
        count++;
      };

      app.dialog.close();
      app.dialog.progress('分析中...', "green");
      var set_id = setInterval(function () {
        countup();

        if (count >= 12) {
          clearInterval(set_id);
          app.dialog.close();
          app.dialog.confirm("接続に時間がかかっています。<br>時間をおいて再度アクセスしてください。", function () {
            app.views.current.router.navigate('/form/');
          });
        } else {
          app.request.promise.post(app.api_url, data, 'json').then(function (res) {
            //console.log('----- GET ESTIMATION ESTIMATION ID -----');
            var data = res.data; //console.log(data);

            var estimation_id = data.estimation_id;
            var measure_flg = data.measure_flg;
            var code = data.code;
            var msg = data.msg;
            /*
            console.log('----- ESTIMATION ID -----');
            console.log(estimation_id);
            console.log('----- MEASURE FLG -----');
            console.log(measure_flg);
            console.log('----- CODE -----');
            console.log(code);
            console.log('----- MESSAGE -----');
            console.log(msg);
            */

            if (estimation_id != "" && estimation_id != undefined) {
              sessionStorage.setItem('estimationId', estimation_id);

              if (data.get.result) {
                var measurements = data.get.measurements[0];
                var status = measurements.status; //console.log("[status]");
                //console.log(status);

                if (status == 'completed') {
                  var size = "取得不可";
                  var estimation_status = measurements.estimation.status;
                  var created_at = measurements.created_at;
                  clearInterval(set_id); // タイマークリア
                  //console.log("[size]");
                  //console.log(size);

                  if (estimation_status == 'success') {
                    app.dialog.close();
                    toastSuccess.open();
                    sessionStorage.setItem('measurementsData', JSON.stringify(measurements));
                    var data = {
                      action: 'finish_measurement',
                      order_id: app.order_id
                    };
                    app.request.promise.post(app.api_url, data, 'json').then(function (res) {
                      sessionStorage.setItem('measure_flg', measure_flg);
                    });
                  }

                  if (estimation_status == 'fail') {
                    app.dialog.close();
                    toastError.open();
                  }
                }
              } else {
                var status = data.get.status;
                var message = data.get.message;
                var message_jp = "";

                switch (status) {
                  case 400:
                    message_jp = "無効なリクエストです";
                    break;

                  case 401:
                    message_jp = "認証に失敗しました";
                    break;

                  case 403:
                    message_jp = "アクセス制限がかかっています";
                    break;

                  case 404:
                    message_jp = "このページは存在しません";
                    break;

                  case 500:
                    message_jp = "内部サーバーエラーが発生しています";
                    break;

                  case 503:
                    message_jp = "アクセス数の制限を超えています";
                    break;

                  default:
                    message_jp = "不明なエラーが発生しました";
                }

                app.dialog.alert(status + "<br>" + message + "<br>" + message_jp, "エラー");
                clearInterval(set_id);
              }
            } else {
              clearInterval(set_id);
              app.dialog.close();
              app.dialog.alert("認証に失敗しています。<br>再度認証用URLから認証を行って下さい");
              app.views.current.router.navigate('/');
            }
          }, function (e) {
            app.dialog.close();
            app.dialog.alert('サーバー側で問題が発生しました');
            clearInterval(set_id);
          });
        }
      }, 5000);
    },
    pageAfterIn: function (e, page) {
      const {
        frontImageURI,
        sideImageURI
      } = this.getImageUriFromStorage();
      const $frontImgElem = $$('#front-image');
      const $sideImgElem = $$('#side-image');
      const $frontImgLink = $$('.external.front');
      const $sideImgLink = $$('.external.side');
      $frontImgElem.on('load', function () {
        URL.revokeObjectURL(frontImageURI);
        sessionStorage.removeItem('bgFrontImageURI');
      });
      $sideImgElem.on('load', function () {
        URL.revokeObjectURL(sideImageURI);
        sessionStorage.removeItem('bgSideImageURI');
      });
      this.frontImageURI = frontImageURI;
      this.sideImageURI = sideImageURI;
      $frontImgLink.attr('href', frontImageURI);
      $sideImgLink.attr('href', sideImageURI);
      this.$update();
    }
  },
  id: 'fee7fed43e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=complete><div class=navbar><div class="navbar-bg bg-color-white blur"></div><div class="navbar-inner sliding"><div class="title text-color-black">アップロード・分析</div></div></div><div class="subnavbar bg-color-white blur"><div class=subnavbar-inner><div id=flow-state><div class="block row"><div class="col finish"><img src=';
      r += c(ctx_1.stateIcon01, ctx_1);
      r += '> <small>身体情報</small></div><div class="col finish"><img src=';
      r += c(ctx_1.stateIcon02, ctx_1);
      r += '> <small>測定</small></div><div class="col active"><img src=';
      r += c(ctx_1.stateIcon03, ctx_1);
      r += '> <small>分析</small></div></div></div></div></div><div class=page-content><!--\n            <div class="block center">\n                <p>下記内容で測定中です</p>\n                <p>完了までしばらく時間がかかります。</p>\n                <p>しばらくお待ち下さい。</p>\n            </div>\n            --><div class=image-horizontal-container><a href=# class="link external front" download=front_complete.jpeg><img id=front-image class=blocked src=';
      r += c(ctx_1.frontImageURI, ctx_1);
      r += '></a><a href=# class="link external front" download=side_complete.jpeg><img id=side-image class=blocked src=';
      r += c(ctx_1.sideImageURI, ctx_1);
      r += '></a></div><div class="card four_table"><div class="card-content card-content-padding"><div class="row no-gap"><div class=col-25>ｻｲｽﾞ区分</div><div class=col-25>年齢</div><div class=col-25>身長</div><div class=col-25>体重</div><div class=col-25>';
      r += c(ctx_1.gender, ctx_1);
      r += 'ｻｲｽﾞ</div><div class=col-25>';
      r += c(ctx_1.height, ctx_1);
      r += ' cm</div><div class=col-25>';
      r += c(ctx_1.weight, ctx_1);
      r += ' kg</div><div class=col-25>';
      r += c(ctx_1.age, ctx_1);
      r += ' 歳</div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};