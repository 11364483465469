import $$ from 'dom7';
export default {
  data: function () {
    var host = this.$app.data.host;
    return {
      host: host,
      stateIcon01: `${host}/images/state01.svg`,
      stateIcon02: `${host}/images/state02.svg`,
      stateIcon03: `${host}/images/state03.svg`
    };
  },
  methods: {
    nextTutorial: function () {
      this.swiper.slideNext();
      $$('.page-content').scrollTop(0);
      $$('.popup .navbar .title').text('撮影の流れ');
    },
    prevTutorial: function () {
      if (this.swiper.previousIndex == 0) {
        this.swiper.slidePrev();
      } else {
        this.$app.views.current.router.navigate('/form/');
      }

      $$('.page-content').scrollTop(0);
      $$('.popup .navbar .title').text('服装＆環境のチェック');
    },
    closeTutorial: function () {
      //this.$app.views.current.router.navigate('/camera/default');
      console.log(this);
      this.swiper.close();
    },
    startShooting: function () {
      this.$app.views.current.router.navigate('/camera/default');
    }
  },
  on: {
    pageInit: function (e, page) {
      this.swiper = page.app.swiper.create('.swiper-container', {
        autoHeight: true,
        speed: 400 //allowTouchMove: false

      });
    }
  },
  id: 'd015d50517',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class=navbar><div class="navbar-bg bg-color-white blur"></div><div class="navbar-inner sliding"><div class=left><div class=left><a href=# class=text-color-pink @click=prevTutorial><i class="icon f7-icons">arrow_left</i> 戻る</a></div></div><div class="title text-color-black">服装＆環境のチェック</div><div class=right><a href=#><i class="icon f7-icons"></i></a></div></div></div><div class=page-content><div id=flow-state><div class="block row"><div class="col finish"><img src=';
      r += c(ctx_1.stateIcon01, ctx_1);
      r += '> <small>身体情報</small></div><div class="col active"><img src=';
      r += c(ctx_1.stateIcon02, ctx_1);
      r += '> <small>測定</small></div><div class=col><img src=';
      r += c(ctx_1.stateIcon03, ctx_1);
      r += '> <small>分析</small></div></div></div><div class=swipe-box><div class="swiper-container swiper-init guide-swiper"><div class=swiper-wrapper><div class=swiper-slide><img src=';
      r += c(ctx_1.host, ctx_1);
      r += '/images/tutorial01.jpg><div class=block><a href=# class="button button-large button-fill color-pink" @click=nextTutorial>次へ</a></div></div><div class=swiper-slide><div class=block-title>撮影してもらう</div><div class="block block-strong"><strong>撮影開始後カメラが起動しない場合は、スマートフォンおよびタブレットの設定からブラウザのカメラ接続を許可してください。</strong><br><br><strong>中央の十字マークの線が動かない場合は、スマートフォンおよびタブレットの設定からブラウザのモーションセンサーを有効化してください。</strong></div><img src=';
      r += c(ctx_1.host, ctx_1);
      r += '/images/tutorial02.jpg><div class=block><a href=# class="button button-large button-fill color-pink" @click=startShooting><i class="icon f7-icons">camera_fill</i>&nbsp;&nbsp;撮影開始</a></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};