import $$ from 'dom7';
const DEFAULT_HEIGHT = 165; // 身長 初期値

const PICKER_MIN_HEIGHT = 50; // 身長 最小値

const PICKER_MAX_HEIGHT = 230; // 身長 最大値

const DEFAULT_WEIGHT = 55; // 体重 初期値

const PICKER_MIN_WEIGHT = 10; // 体重 最小値

const PICKER_MAX_WEIGHT = 120; // 体重 最大値

const DEFAULT_AGE = 30; // 年齢 初期値

const PICKER_MIN_AGE = 0; // 年齢 最小値

const PICKER_MAX_AGE = 120; // 年齢 最大値

export default {
  data: function () {
    var host = this.$app.data.host;
    return {
      host: host,
      stateIcon01: `${host}/images/state01.svg`,
      stateIcon02: `${host}/images/state02.svg`,
      stateIcon03: `${host}/images/state03.svg`,
      height: undefined,
      height_decimal: 0,
      weight: undefined,
      weight_decimal: 0,
      age: undefined,
      gender: undefined
    };
  },
  methods: {
    clickRadio: function (gender) {
      // ラジオボタン選択時の色変更
      $$('label.radio').removeClass('bg-color-pink text-color-white border-color-pink');
      $$('label.radio.' + gender).addClass('bg-color-pink text-color-white border-color-pink');
    },
    pickerDescribe: function (name) {
      var value = '';
      var left = [],
          right = [];

      for (var i = 0; i < 10; i++) right.push(i);

      switch (name) {
        case 'height':
          value = [this.height, '.', this.height_decimal];

          for (var i = PICKER_MIN_HEIGHT; i <= PICKER_MAX_HEIGHT; i++) left.push(i);

          break;

        case 'weight':
          value = [this.weight, '.', this.weight_decimal];

          for (var i = PICKER_MIN_WEIGHT; i <= PICKER_MAX_WEIGHT; i++) left.push(i);

          break;

        case 'age':
          value = [this.age];

          for (var i = PICKER_MIN_AGE; i <= PICKER_MAX_AGE; i++) left.push(i);

          break;
      }

      var cols = [{
        textAlign: 'left',
        values: left
      }, {
        textAlign: 'center',
        values: ['.']
      }, {
        textAlign: 'right',
        values: right
      }];

      if (name == 'age') {
        cols = [{
          textAlign: 'center',
          values: left
        }];
      }

      this.$app.picker.create({
        inputEl: `#picker-describe-${name}`,
        rotateEffect: true,
        toolbarCloseText: '完了',
        value: value,
        // 初期値
        cols: cols
      });
    },
    saveForm: function () {
      var opened = sessionStorage.getItem('openTutorial');
      var formData = this.$app.form.convertToData('#body-data-form');
      var height = this.removeSpace(formData.height);
      var weight = this.removeSpace(formData.weight);
      var age = formData.age;
      var gender = formData.gender;
      var bodyData = {
        height: parseInt(height * 10),
        weight: parseInt(weight * 1000),
        age: parseInt(age),
        gender: gender
      };
      sessionStorage.setItem('bodyData', JSON.stringify(bodyData)); //this.$app.views.current.router.navigate('/camera/default');
      //this.openCamera();
      //this.$app.methods.popupGuide();

      if (opened == "opened") {
        this.$app.views.current.router.navigate('/camera/default');
      } else {
        this.$app.views.current.router.navigate('/howto/');
      }
    },
    removeSpace: function (d) {
      return d.replace(/ /g, '');
    },
    openCamera: function () {
      if (DeviceMotionEvent && DeviceMotionEvent.requestPermission && typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission().then(function (response) {
          if (response == 'granted') {
            window.addEventListener('devicemotion', function (e) {
              // do something with e
              console.log(e);
            });
          }
        }).catch(console.error);
      }

      if (DeviceOrientationEvent && DeviceOrientationEvent.requestPermission && typeof DeviceOrientationEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission().then(function (response) {
          if (response == 'granted') {
            window.addEventListener('deviceorientation', function (e) {
              // do something with e
              console.log(e);
            });
          }
        }).catch(console.error);
      }
    }
  },
  on: {
    pageAfterIn: function (e, page) {
      sessionStorage.setItem('termRead', true);
      var bodyData = sessionStorage.getItem('bodyData'); // 小数点以下のみを取得

      function getAfterPoint(num) {
        var arr = String(num).split('.');

        if (arr[1]) {
          return Number('0.' + arr[1]);
        } else {
          return 0; // 小数点以下がない場合は0を返す
        }
      }

      if (bodyData != null) {
        bodyData = JSON.parse(bodyData);
        var height = bodyData.height / 10;
        var decimal_h = getAfterPoint(height);
        this.height = Math.floor(height);
        this.height_decimal = decimal_h == 0 ? 0 : decimal_h * 10;
        var weight = bodyData.weight / 1000;
        var decimal_w = getAfterPoint(weight);
        this.weight = Math.floor(weight);
        this.weight_decimal = decimal_w == 0 ? 0 : decimal_w * 10;
        this.age = bodyData.age;
        $$(`input[value="${bodyData.gender}"]`).prop('checked', true);
        $$(`label.radio.${bodyData.gender}`).addClass('bg-color-pink text-color-white border-color-pink');
      } else {
        this.height = DEFAULT_HEIGHT;
        this.weight = DEFAULT_WEIGHT;
        this.age = DEFAULT_AGE;
      }

      $$('#body-data-form').on('change', function (e) {
        var bd = page.app.input.validateInputs('#body-data-form');

        if (bd) {
          $$('#save-form').removeClass("disabled");
        } else {
          $$('#save-form').addClass("disabled");
        }
      });
      this.pickerDescribe('height'); // 身長 picker生成

      this.pickerDescribe('weight'); // 体重 picker生成

      this.pickerDescribe('age'); // 年齢 picker生成

      this.$update();
    }
  },
  id: '860061949b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><div class=navbar><div class="navbar-bg bg-color-white blur"></div><div class="navbar-inner sliding"><div class=left><a href=/ class="link text-color-pink"><i class="icon f7-icons">arrow_left</i> 戻る</a></div><div class="title text-color-black">身体情報の入力</div><div class=right><a href=# class="link icon-only"><i class="icon f7-icons"></i></a></div></div></div><div class="subnavbar bg-color-white blur"><div class=subnavbar-inner><div id=flow-state><div class=row><div class="col active"><img src=';
      r += c(ctx_1.stateIcon01, ctx_1);
      r += '> <small>身体情報</small></div><div class=col><img src=';
      r += c(ctx_1.stateIcon02, ctx_1);
      r += '> <small>測定</small></div><div class=col><img src=';
      r += c(ctx_1.stateIcon03, ctx_1);
      r += '> <small>分析</small></div></div></div></div></div><div class="toolbar toolbar-bottom"><div class=block><a href=# id=save-form class="button button-large button-fill disabled" @click=saveForm>次へ</a></div></div><div class=page-content><form id=body-data-form><div class=block-title>サイズ区分</div><div class="block row"><label class="col radio male" @click="clickRadio(\'male\')"><input type=radio name=gender value=male required validate> <span>男性サイズ</span></label> <label class="col radio female" @click="clickRadio(\'female\')"><input type=radio name=gender value=female required validate> <span>女性サイズ</span></label></div><div class="list no-hairlines"><ul><li class="item-content item-input item-input-outline"><div class=item-inner><div class=item-title>年 齢</div><div class=item-input-wrap><input type=text name=age readonly=readonly id=picker-describe-age required validate> <span class=input-clear-button></span></div></div></li><li><div class="item-content item-input item-input-outline"><div class=item-inner><div class=item-title>身 長<span>※0.1cm単位で入力してください</span></div><div class=item-input-wrap><input type=text name=height readonly=readonly id=picker-describe-height required validate> <span class=input-clear-button></span></div></div></div></li><li><div class="item-content item-input item-input-outline"><div class=item-inner><div class=item-title>体 重<span>※0.1cm単位で入力してください</span></div><div class="item-input-wrap row"><input type=text name=weight readonly=readonly id=picker-describe-weight required validate> <span class=input-clear-button></span></div></div></div></li></ul></div></form></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};