export default {
  id: '9107888599',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=app><!-- Your main view, should have "view-main" class --><div class="view view-main view-init safe-areas" data-url=/ ></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};