import $$ from 'dom7'; //

export default {
  data: function () {
    var host = this.$app.data.host;
    return {
      host: host,
      frontGuideImg: `${host}/images/front.svg`,
      sideGuideImg: `${host}/images/side.svg`,
      markYl: `${host}/images/mark_yl.svg`,
      markPk: `${host}/images/mark_pk.svg`,
      markBl: `${host}/images/mark_bl.svg`,
      frontImageURI: undefined,
      sideImageURI: undefined,
      btnDisabled: 'disabled',
      requestId: '',
      frontImage: '',
      sideImage: '',
      created_at: undefined,
      // 採寸日時
      height: undefined,
      // 身長
      weight: undefined,
      // 体重
      age: undefined,
      // 年齢
      gender: undefined,
      // 性別
      neck: undefined,
      // 首回り
      shoulder: undefined,
      // 肩幅
      upper_arm: undefined,
      // 二の腕周り(右)
      sleeve: undefined,
      // 裄丈
      arm_length: undefined,
      // 腕の長さ(右)
      bust: undefined,
      // バスト
      waist: undefined,
      // ウエスト
      hip: undefined,
      // ヒップ
      inseam: undefined,
      // 股下
      thigh: undefined,
      // 太もも周り(右)
      paramNames: ['bicepCircumference', 'calfCircumference', 'chestCircumference', 'highHipCircumference', 'hipCircumference', 'inseamLength', 'kneeCircumference', 'neckCircumference', 'outseamLength', 'shoulderWidth', 'sleeveLength', 'thighCircumference', 'totalLength', 'waistCircumference', 'wristCircumference']
    };
  },
  methods: {
    getImageUriFromStorage: function () {
      const frontImageURI = sessionStorage.getItem('bgFrontImageURI');
      const sideImageURI = sessionStorage.getItem('bgSideImageURI');

      if (!frontImageURI || !sideImageURI) {
        throw new Error('ImageURI is not set.');
      }

      return {
        frontImageURI,
        sideImageURI
      };
    },
    finish: function () {
      var self = this;
      var app = self.$app;
      app.views.current.router.navigate('/');
    },

    format(value) {
      return value.toFixed(1);
    },

    fetchResult() {
      const callback = ({
        request,
        errors
      }) => {
        if (errors && errors.length) {
          // エラーハンドリング
          errors.forEach(error => {
            console.log(error);
          });
          return;
        }

        if (!request) {
          throw new Error('Request should exist.');
        }

        this.age = request.age;
        this.gender = request.gender;
        this.height = request.height;
        this.weight = request.weight;
        const guideScale = Math.min(this.height / MAX_HEIGHT, 1.0);
        $$('#front-image-guide').css('transform', `scale(${guideScale})`);
        $$('#side-image-guide').css('transform', `scale(${guideScale})`);

        if (request.frontImage) {
          const urlPromise = request.frontImage.downloadableURL;
          urlPromise.then(res => {
            $$('#front-image-src').attr('src', res);
          });
        }

        if (request.sideImage) {
          const urlPromise = request.sideImage.downloadableURL;
          urlPromise.then(res => {
            $$('#side-image-src').attr('src', res);
          });
        }

        if (request.result) {
          this.paramNames.forEach(name => {
            $$(`#${name}`).html(`${this.format(request.result[name])} cm`);
          });
        }
      };
    }

  },
  on: {
    pageInit: function (e, page) {
      var self = this; // 採寸イメージ

      $$(document).on('click', '#open-size-list', function () {
        //console.log(self);
        self.$app.methods.openSizeList();
        $$(this).off('click');
      });
    },
    pageMounted: function (e, page) {//this.requestId = req.query.requestId;
      //this.fetchResult();
    },
    pageAfterIn: function (e, page) {
      var self = this;
      var app = page.app;
      var measure_flg = sessionStorage.getItem('measure_flg');

      if (measure_flg < 2) {
        self.btnDisabled = "";
      } else {
        self.btnDisabled = "disabled";
      }

      var requestInput = JSON.parse(sessionStorage.getItem('bodyData'));
      this.height = requestInput.height / 10;
      this.weight = requestInput.weight / 1000;
      this.age = requestInput.age;
      this.gender = requestInput.gender === 'male' ? '男性' : '女性';
      const {
        frontImageURI,
        sideImageURI
      } = this.getImageUriFromStorage();
      const $frontImgElem = $$('#front-image');
      const $sideImgElem = $$('#side-image');
      var data = {
        action: 'get_estimation_result',
        order_id: app.order_id,
        estimation_id: app.estimation_id
      };
      app.request.promise.post(app.api_url, data, 'json').then(function (res) {
        if (res.data.state == "success") {
          var data = res.data.get;
          self.height = data.height / 10;
          self.weight = data.weight / 1000;
          self.gender = data.gender;
          self.age = data.age;
          var d = new Date(data.created_at.replace(/-/g, "/"));
          self.created_at = d.toLocaleString('ja-JP');
          self.neck = data.neck_girth / 10;
          self.shoulder = data.across_back_shoulder_width / 10;
          self.upper_arm = data.upper_arm_girth_r / 10;
          self.sleeve = data.back_neck_point_to_wrist_length_r / 10;
          self.arm_length = data.outer_arm_length_r / 10;
          self.bust = data.bust_girth / 10;
          self.waist = data.waist_girth / 10;
          self.hip = data.hip_girth / 10;
          self.inseam = data.inside_leg_height / 10;
          self.thigh = data.thigh_girth_r / 10;
        }

        self.$update();
      }, function (err) {
        console.log(err);
      });
      $frontImgElem.on('load', function () {
        URL.revokeObjectURL(frontImageURI);
        sessionStorage.removeItem('bgFrontImageURI');
      });
      $sideImgElem.on('load', function () {
        URL.revokeObjectURL(sideImageURI);
        sessionStorage.removeItem('bgSideImageURI');
      });
      this.frontImageURI = frontImageURI;
      this.sideImageURI = sideImageURI;
    }
  },
  id: '721bf2b79d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=result><div class=navbar><div class="navbar-bg bg-color-white blur"></div><div class="navbar-inner sliding"><div class="title text-color-black">測定結果</div></div></div><div class="toolbar toolbar-bottom"><div class=block><a href=# class="button button-large button-fill" @click=finish>計測完了</a></div></div><div id=result class=page-content><div class=card><div class=card-header><div class=row><a href=# class="col text-color-gray">測定データ</a> <a href=# class=col></a> <a href=# id=open-size-list class="col link text-color-pink">ヘルプ</a></div><span>測定日時</span> <a href=# class=created_at>';
      r += c(ctx_1.created_at, ctx_1);
      r += '</a></div><div class=card-content><div class=list><ul><li><div class=item-content><div class=item-media><i class="icon f7-icons">heart_circle_fill</i></div><div class=item-inner><div class=item-title>サイズ区分</div><div class=item-after>';
      r += c(ctx_1.gender, ctx_1);
      r += ' サイズ</div></div></div></li><li><div class=item-content><div class=item-media><i class="icon f7-icons">heart_circle_fill</i></div><div class=item-inner><div class=item-title>身長</div><div class=item-after>';
      r += c(ctx_1.height, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><i class="icon f7-icons">heart_circle_fill</i></div><div class=item-inner><div class=item-title>体重</div><div class=item-after>';
      r += c(ctx_1.weight, ctx_1);
      r += ' kg</div></div></div></li><li><div class=item-content><div class=item-media><i class="icon f7-icons">heart_circle_fill</i></div><div class=item-inner><div class=item-title>年齢</div><div class=item-after>';
      r += c(ctx_1.age, ctx_1);
      r += ' 歳</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markYl, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>首回り</div><div class=item-after>';
      r += c(ctx_1.neck, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markYl, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>肩幅</div><div class=item-after>';
      r += c(ctx_1.shoulder, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markPk, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>二の腕周り</div><div class=item-after>';
      r += c(ctx_1.upper_arm, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markPk, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>裄丈</div><div class=item-after>';
      r += c(ctx_1.sleeve, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markPk, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>腕の長さ</div><div class=item-after>';
      r += c(ctx_1.arm_length, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markBl, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>バスト</div><div class=item-after>';
      r += c(ctx_1.bust, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markBl, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>ウエスト</div><div class=item-after>';
      r += c(ctx_1.waist, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markBl, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>ヒップ</div><div class=item-after>';
      r += c(ctx_1.hip, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markBl, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>股下</div><div class=item-after>';
      r += c(ctx_1.inseam, ctx_1);
      r += ' cm</div></div></div></li><li><div class=item-content><div class=item-media><img src=';
      r += c(ctx_1.markBl, ctx_1);
      r += '></div><div class=item-inner><div class=item-title>太もも周り</div><div class=item-after>';
      r += c(ctx_1.thigh, ctx_1);
      r += ' cm</div></div></div></li></ul></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};