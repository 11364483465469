import $$ from 'dom7';
export default {
  data: function () {
    return {
      host: this.$app.data.host,
      splash: undefined,
      history: undefined,
      detail: undefined,
      size: undefined,
      historyDisabled: 'disabled',
      agreementDisabled: 'disabled',
      display: undefined,
      created_at: undefined,
      // 採寸日時
      height: undefined,
      // 身長
      weight: undefined,
      // 体重
      age: undefined,
      // 年齢
      gender: undefined,
      // 性別
      neck: undefined,
      // 首回り
      shoulder: undefined,
      // 肩幅
      upper_arm: undefined,
      // 二の腕周り(右)
      sleeve: undefined,
      // 裄丈(右)
      arm_length: undefined,
      // 腕の長さ(右)
      bust: undefined,
      // バスト
      waist: undefined,
      // ウエスト
      hip: undefined,
      // ヒップ
      inseam: undefined,
      // 股下 (股下から床)
      thigh: undefined // 太もも周り(右)

    };
  },
  methods: {
    openHistory: function () {
      var self = this;
      var app = self.$app;
      self.detail = `<div class="card">
                    <div class="card-header">
                        <div class="row">
                            <a href="#" class="col text-color-gray">測定データ</a>
                            <a href="#" class="col"></a>
                            <a href="#" id="open-size-list" class="col link text-color-pink">ヘルプ</a>
                        </div>
                        <span>測定日時</span>
                        <a href="#" class="create_at">${self.created_at}</a>
                    </div>
                    <div class="card-content">
                        <div class="list">
                            <ul>
                                <li>
                                    <div class="item-content">
                                        <div class="item-media">
                                            <i class="icon f7-icons">heart_circle_fill</i>
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title">サイズ区分</div>
                                            <div class="item-after">${self.gender}サイズ</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="item-content">
                                        <div class="item-media">
                                            <i class="icon f7-icons">heart_circle_fill</i>
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title">身長</div>
                                            <div class="item-after">${self.height} cm</div>
                                        </div>
                                    </div>
                                </li>
                                    
                                <li>
                                    <div class="item-content">
                                        <div class="item-media">
                                            <i class="icon f7-icons">heart_circle_fill</i>
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title">体重</div>
                                            <div class="item-after">${self.weight} kg</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media">
                                            <i class="icon f7-icons">heart_circle_fill</i>
                                        </div>
                                        <div class="item-inner">
                                            <div class="item-title">年齢</div>
                                            <div class="item-after">${self.age} 歳</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_yl.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">首回り</div>
                                            <div class="item-after">${self.neck} cm</div>
                                        </div>
                                    </div>
                                </li>
                                    
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_yl.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">肩幅</div>
                                            <div class="item-after">${self.shoulder} cm</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_pk.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">二の腕周り</div>
                                            <div class="item-after">${self.upper_arm} cm</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_pk.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">裄丈</div>
                                            <div class="item-after">${self.sleeve} cm</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_pk.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">腕の長さ</div>
                                            <div class="item-after">${self.arm_length} cm</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_bl.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">バスト</div>
                                            <div class="item-after">${self.bust} cm</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_bl.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">ウエスト</div>
                                            <div class="item-after">${self.waist} cm</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_bl.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">ヒップ</div>
                                            <div class="item-after">${self.hip} cm</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_bl.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">股下</div>
                                            <div class="item-after">${self.inseam} cm</div>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <div class="item-content">
                                        <div class="item-media"><img src="${self.host}/images/mark_bl.svg"></div>
                                        <div class="item-inner">
                                            <div class="item-title">太もも周り</div>
                                            <div class="item-after">${self.thigh} cm</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>`;
      self.history = app.popup.create({
        swipeToClose: false,
        animate: true,
        content: `<div id="history" class="popup">
                        <div class="view">
                            <div class="page">
                                <div class="navbar">
                                    <div class="navbar-bg bg-color-white blur"></div>
                                    <div class="navbar-inner">
                                        <div class="left">
                                            <a href="#" class="link icon-only">
                                                <i class="icon f7-icons"></i>
                                            </a>
                                        </div>
                                        <div class="title text-color-black">測定結果</div>
                                        <div class="right">
                                            <a href="#" class="popup-close link text-color-pink icon-only"><i class="icon f7-icons">multiply_circle_fill</i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="page-content">${self.detail}</div>
                            </div>
                        </div>
                    </div>`,
        on: {
          opened: function (e) {}
        }
      });
      self.history.open();
    },
    measuringStart: function () {
      var self = this.$app;
      var term_read = sessionStorage.getItem('termRead');
      var term_link = "";

      if (!term_read) {
        term_link = `<div class="footer">
                        <p>続行することでSUMAピタの<a href="#" id="open-terms" class="color-pink">利用規約</a>および<a href="#" id="open-privacy" class="color-pink">プライバシーポリシー</a>に同意し<br>理解したものとみなされます</p>
                        <div class="block">
                            <a href="#" id="term-agree" class="term-btn button button-large button-fill color-pink">計測を始める</a>
                        </div>
                    </div>`;
      }

      self.splash = self.popup.create({
        swipeToClose: false,
        animate: false,
        content: `<div id="splash" class="popup">
                        <div class="logo">
                            <img src="http://sumapita.com/images/bodygram_logo.svg">
                        </div>` + term_link + `</div>`,
        on: {
          opened: function (popup) {
            if (term_read) {
              setTimeout(function () {
                self.splash.close(); //　フォームへ遷移

                $$('#splash').animate({
                  'opacity': '0'
                }, {
                  duration: 200
                });
                self.views.current.router.navigate('/form/');
              }, 2000);
            }
          }
        }
      });
      self.splash.open();
      $$('#splash').animate({
        'opacity': '1'
      }, {
        duration: 200
      });
      $$(document).on('click', '#term-agree', function () {
        self.splash.close(); //　フォームへ遷移

        $$('#splash').animate({
          'opacity': '0'
        }, {
          duration: 200
        });
        self.views.current.router.navigate('/form/');
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      var app = page.app;
      var isGyro = false;

      if (window.DeviceOrientationEvent && "ontouchstart" in window) {
        isGyro = true;
      }

      if (!isGyro) {
        app.dialog.alert("お使いの端末は本サービスに対応しておりません。<br>ジャイロセンサー機能が搭載された端末でサービスをご利用ください。<br>返品の際はお問い合わせフォームよりご連絡ください。", function () {
          window.open('https://www.pitatto-ordermade.jp/company/contact.html', '_self');
        });
      }

      app.preloader.show();
      var term = sessionStorage.getItem('termRead'); //sessionStorage.clear();

      sessionStorage.removeItem('bgFrontImageURI');
      sessionStorage.removeItem('bgSideImageURI'); // 利用規約

      $$(document).on('click', '#open-terms', function () {
        app.methods.openTerms();
        $$(this).off('click');
      }); // プライバシーポリシー

      $$(document).on('click', '#open-privacy', function () {
        app.methods.openPrivacy();
        $$(this).off('click');
      }); // 採寸履歴

      $$(document).on('click', '#open-history', function () {
        self.openHistory();
        $$(this).off('click');
      }); // 採寸イメージ

      $$(document).on('click', '#open-size-list', function () {
        self.$app.methods.openSizeList();
        $$(this).off('click');
      });

      if (term) {
        // 規約既読フラグをセッションストレージに保存
        sessionStorage.setItem('termRead', true); // 規約が既読の場合は撮影開始ボタンをアクティブ化

        self.agreementDisabled = '';
      }

      var data = {
        action: 'get_estimation_result',
        order_id: app.order_id
      };
      app.request.promise.post(app.api_url, data, 'json').then(function (res) {
        //console.log(res);
        var measure_flg = res.data.measure_flg;
        var order_state = res.data.order_state;
        var state = res.data.state;

        if (state == "success") {
          var data = res.data.get;
          self.historyDisabled = '';
          self.agreementDisabled = '';
          self.height = data.height / 10;
          self.weight = data.weight / 1000;
          self.gender = data.gender;
          self.age = data.age;
          var d = new Date(data.created_at.replace(/-/g, "/"));
          self.created_at = d.toLocaleString('ja-JP');
          self.neck = data.neck_girth / 10;
          self.shoulder = data.across_back_shoulder_width / 10;
          self.upper_arm = data.upper_arm_girth_r / 10;
          self.sleeve = data.back_neck_point_to_wrist_length_r / 10;
          self.arm_length = data.outer_arm_length_r / 10;
          self.bust = data.bust_girth / 10;
          self.waist = data.waist_girth / 10;
          self.hip = data.hip_girth / 10;
          self.inseam = data.inside_leg_height / 10;
          self.thigh = data.thigh_girth_r / 10;
          self.display = "block";
        }

        self.agreementDisabled = 'disabled';

        if (state == "error") {//app.methods.invalidURL();
        } else if (measure_flg >= 1) {
          var usedTicket = app.toast.create({
            icon: `<i class="f7-icons">checkmark_alt</i>`,
            text: "このチケットは<br>利用済みです",
            position: 'center',
            closeTimeout: 3000
          });
          usedTicket.open(); // テスト用
          //if(app.order_id == "10000704") self.agreementDisabled = '';
        } else {
          var data = {
            action: 'get_ticket_limit',
            order_id: app.order_id
          };
          app.request.promise.post(app.api_url, data, 'json').then(function (res) {
            if (state == "error") {
              app.dialog.alert("このチケットは、<br>" + res.data.limit + "」<br>に有効期限が切れました<br>計測を行う場合はSUMAピタチケットを再度購入してください", "期限切れ", function () {
                location.href = "https://www.pitatto-ordermade.jp/";
              });
            } else {
              var deadline = app.toast.create({
                icon: `<i class="f7-icons">clock</i>`,
                text: '有効期限<br>' + res.data.limit,
                position: 'center',
                closeTimeout: 5000
              });
              deadline.open();
              self.agreementDisabled = '';
            }
          });
        }

        setTimeout(function () {
          self.$update();
          app.preloader.hide();
        }, 500);
      }, function (err) {
        app.preloader.hide();
        app.dialog.alert("Bodygramへの接続に失敗しました。<br>URLに間違いがないかご確認くください。", "接続エラー");
      });
    }
  },
  id: 'b505359b34',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only"><i class="icon f7-icons"></i></a></div><div class="title sliding"><img src=';
      r += c(ctx_1.host, ctx_1);
      r += '/upload/logo.png></div><div class=right><a href=# class="link icon-only"><i class="icon f7-icons"></i></a></div></div></div><div class="toolbar toolbar-bottom"><div class="block row"><a href=# id=history class="col button button-large button-outline ';
      r += c(ctx_1.historyDisabled, ctx_1);
      r += ' text-color-black" @click=openHistory><i class="icon f7-icons">clock</i>計測履歴 </a><a href=# id=agreement class="col button button-large button-fill ';
      r += c(ctx_1.agreementDisabled, ctx_1);
      r += '" @click=measuringStart><img src=';
      r += c(ctx_1.host, ctx_1);
      r += '/images/measuring_icon.svg>サイズ計測</a></div></div><!-- Scrollable page content--><div class=page-content><img class=banner src=';
      r += c(ctx_1.host, ctx_1);
      r += '/upload/banner.png><div id=about class=block><p>「<strong>SUMAピタ</strong>」は、<br><strong>フォーマルスーツ専門店 メアリーココ</strong>が提供しているバーチャルフィッティングサービスです。<br><br>世界最新のテクノロジーを利用して、スマホで撮影した写真からAIでサイズ計測！<br><br>ご自宅に居ながら楽しく♪ 簡単にピッタリの服をネットでオーダーできます。</p></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};