import $$ from 'dom7';
import exif from '../static/modules/exif.js';
const MAX_HEIGHT = 220.0;
export default {
  data: function () {
    var host = this.$app.data.host;
    return {
      host: host,
      frontGuideImg: `${host}/images/front.svg`,
      sideGuideImg: `${host}/images/side.svg`,
      stateIcon01: `${host}/images/state01.svg`,
      stateIcon02: `${host}/images/state02.svg`,
      stateIcon03: `${host}/images/state03.svg`,
      frontImageBlob: undefined,
      sideImageBlob: undefined,
      frontImageURI: undefined,
      sideImageURI: undefined,
      estimationParameter: undefined,
      bodybank: undefined,
      height: undefined,
      weight: undefined,
      age: undefined,
      gender: undefined
    };
  },
  methods: {
    getImageUriFromStorage: function () {
      const frontImageURI = sessionStorage.getItem('bgFrontImageURI');
      const sideImageURI = sessionStorage.getItem('bgSideImageURI');

      if (!frontImageURI || !sideImageURI) {
        throw new Error('imageURIが設定されていません');
      }

      return {
        frontImageURI,
        sideImageURI
      };
    },
    createImage: function (blobUrl) {
      return new Promise(resolve => {
        this.blobUrlToBlob(blobUrl).then(this.convertBlobToBinary).then(this.insertExif).then(this.convertBinaryToBlob).then(dataURI => {
          resolve(dataURI);
        });
      });
    },
    insertExif: function (binary) {
      return new Promise(resolve => {
        resolve(exif.insertExifRotation(binary));
      });
    },
    convertBinaryToBlob: function (binary) {
      return new Promise(resolve => {
        var array = [];

        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }

        resolve(new Blob([new Uint8Array(array)], {
          type: 'image/jpeg'
        }));
      });
    },
    convertBlobToBinary: function (blob) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();

        fr.onload = eve => {
          resolve(fr.result);
        };

        fr.onerror = eve => {
          reject(fr.error);
        };

        fr.readAsBinaryString(blob);
      });
    },
    blobUrlToBlob: function (blobUrl) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest();

        xhr.onload = function () {
          resolve(xhr.response);
        };

        xhr.responseType = "blob";
        xhr.open("GET", blobUrl);
        xhr.send();
      });
    },
    generateEstimationParameter: function () {
      const {
        height,
        weight,
        age,
        gender
      } = this;
      this.estimationParameter = {
        age,
        heightInCm: `${height}`,
        weightInKg: `${weight}`,
        //gender: this.convertGenderToBG(gender),
        gender,
        failOnAutomaticEstimationFailure: true
      };
      this.estimationParameter.frontImage = this.frontImageBlob;
      this.estimationParameter.sideImage = this.sideImageBlob;
    },
    convertGenderToBG: function (gender) {
      switch (gender) {
        case 'male':
          return bodygram.Gender.male;

        case 'female':
          return bodygram.Gender.female;

        default:
          return bodygram.Gender.male;
      }
    },
    createRequest: function () {
      var app = this.$app;
      app.dialog.progress('アップロード中...', "pink");
      var bodyData = JSON.parse(sessionStorage.getItem('bodyData'));
      var height = bodyData.height;
      var weight = bodyData.weight;
      var age = bodyData.age;
      var gender = bodyData.gender;

      switch (gender) {
        case "male":
          gender = 0;
          break;

        case "female":
          gender = 1;
          break;
      }

      var front = this.estimationParameter.frontImage;
      var side = this.estimationParameter.sideImage;
      var front_uri = URL.createObjectURL(front);
      var side_uri = URL.createObjectURL(side);
      var data = {
        action: 'post_estimation',
        order_id: app.order_id,
        height: height * 10,
        weight: weight * 1000,
        age: age,
        gender: gender
      };
      app.request.promise.post(app.api_url, data, 'json').then(function (res) {
        console.log('----- POST ESTIMATION -----');
        console.log(res.data);

        if (res.data.state == 'error') {
          app.dialog.close();
          app.dialog.confirm(res.data.msg, "ConfirmationErr", function () {
            app.views.current.router.navigate('/form/');
          });
        } else {
          var state = res.data.state;
          app.estimation_id = res.data.estimation_id;
          console.log('----- STATE -----');
          console.log(state);
          console.log('----- ESTIMATION ID -----');
          console.log(app.estimation_id);
          var fd = new FormData();
          fd.append("action", "post_estimation_image");
          fd.append("estimation_id", app.estimation_id);
          fd.append("order_id", app.order_id);
          fd.append("front", front, 'front.jpeg');
          fd.append("side", side, 'front.jpeg');
          app.request.promise.post(app.api_url, fd, 'json').then(function (res) {
            console.log('----- POST ESTIMATION IMAGE -----');
            var toastPicSend = app.toast.create({
              icon: `<i class="f7-icons">photo_on_rectangle</i>`,
              text: "画像送信完了",
              position: 'center',
              closeTimeout: 1500
            });
            toastPicSend.open();
            var state = res.data.state;
            console.log(res.data.msg);

            if (state) {
              app.views.current.router.navigate('/complete/');
            }
          });
        }
      }, function (err) {
        var status = err.status;
        var message = err.message;
        var message_jp = "";

        switch (status) {
          case 400:
            message_jp = "無効なリクエストです";
            break;

          case 401:
            message_jp = "認証に失敗しました";
            break;

          case 403:
            message_jp = "アクセス制限がかかっています";
            break;

          case 404:
            message_jp = "このページは存在しません";
            break;

          case 500:
            message_jp = "内部サーバーエラーが発生しています";
            break;

          case 503:
            message_jp = "アクセス数の制限を超えています";
            break;

          default:
            message_jp = "予期せぬエラーが発生しました";
        }

        app.dialog.close();
        app.dialog.confirm(message_jp + "<br>" + "エラーコード: " + status + "<br>" + "エラーメッセージ: " + message, "ConfirmationErr", function () {
          app.dialog.alert('送信しました');
        });
      });
    },
    estimate: function () {
      this.createRequest();
    }
  },
  on: {
    pageInit: function (e, page) {
      var requestInput = JSON.parse(sessionStorage.getItem('bodyData'));
      console.log(requestInput);
      this.height = requestInput.height / 10;
      this.weight = requestInput.weight / 1000;
      this.age = requestInput.age;
      this.gender = requestInput.gender === 'male' ? '男性' : '女性';
      $$('.age').html(`${this.age} 歳`);
      $$('.gender').html(`${this.gender}ｻｲｽﾞ`);
      $$('.height').html(`${this.height} cm`);
      $$('.weight').html(`${this.weight} kg`);
      const {
        frontImageURI,
        sideImageURI
      } = this.getImageUriFromStorage();
      const $frontImgElem = $$('#front-image-src');
      const $sideImgElem = $$('#side-image-src');
      const $frontImgLink = $$('.external.front');
      const $sideImgLink = $$('.external.side');
      $frontImgElem.attr('src', frontImageURI);
      $sideImgElem.attr('src', sideImageURI);
      $frontImgLink.attr('href', frontImageURI);
      $sideImgLink.attr('href', sideImageURI);
      this.frontImageURI = frontImageURI;
      this.sideImageURI = sideImageURI;
      var scale = Math.min(this.height / MAX_HEIGHT, 1.0);
      this.createImage(frontImageURI).then(blob => {
        this.frontImageBlob = blob;
      }).then(this.createImage.bind(this, sideImageURI)).then(blob => {
        this.sideImageBlob = blob;
      }).then(() => {
        this.generateEstimationParameter();
      });
      setTimeout(function () {
        var frontImgHeight = $frontImgElem.height();
        var frontImgWidth = $frontImgElem.width();
        var guideHeight = scale * frontImgHeight;
        $$('#front-image-guide, #side-image-guide').css({
          'display': 'inline-block',
          'height': guideHeight + 'px',
          'width': 'auto',
          'top': frontImgHeight / 2 - guideHeight / 2 + 'px',
          'pointer-events': 'none'
        });
        setTimeout(function () {
          const guideFrontWidth = $$('#front-image-guide').width();
          const guideSideWidth = $$('#side-image-guide').width();
          $$('#front-image-guide').css({
            'left': frontImgWidth / 2 - guideFrontWidth / 2 + 'px'
          });
          $$('#side-image-guide').css({
            'left': frontImgWidth / 2 - guideSideWidth / 2 + 'px'
          });
        }, 200);
      }, 100);
    }
  },
  id: 'da709b4f70',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=guide><div class=navbar><div class="navbar-bg bg-color-white blur"></div><div class="navbar-inner sliding"><div class="title text-color-black">内容確認</div></div></div><div class="toolbar toolbar-bottom"><div class="block row"><a href=/camera/default class="col button button-large button-outline">撮り直す</a> <a href=# @click=estimate class="col button button-large button-fill">測定する</a></div></div><div class=page-content><div class=image-flick-container><div id=front-image class=image-flex-container__item><a href=# class="link external front" download=front.jpeg><img id=front-image-src src=';
      r += c(ctx_1.frontImage, ctx_1);
      r += '></a><!--<div id="front-image-guide" style="background-image: url(';
      r += c(ctx_1.frontGuideImg, ctx_1);
      r += ');"></div>--> <img id=front-image-guide src=';
      r += c(ctx_1.frontGuideImg, ctx_1);
      r += '> <span>前面</span></div><div id=side-image class=image-flex-container__item><a href=# class="link external side" download=side.jpeg><img id=side-image-src src=';
      r += c(ctx_1.sideImage, ctx_1);
      r += '></a><!--<div id="side-image-guide" style="background-image: url(';
      r += c(ctx_1.sideGuideImg, ctx_1);
      r += ');"></div>--> <img id=side-image-guide src=';
      r += c(ctx_1.sideGuideImg, ctx_1);
      r += '> <span>側面</span></div></div><div class="card four_table"><div class="card-content card-content-padding"><div class="row no-gap"><div class=col-25>ｻｲｽﾞ区分</div><div class=col-25>年齢</div><div class=col-25>身長</div><div class=col-25>体重</div><div class="gender col-25"></div><div class="age col-25"></div><div class="height col-25"></div><div class="weight col-25"></div></div></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};