import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import Routes
import routes from './routes.js';

// Import main app component
import App from '../app.f7.html';

var app = new Framework7({
    root: '#app', // App root element
    component: App, // App main component
    name: 'SUMAPITA', // App name
    theme: 'auto', // Automatic theme detection
    // App routes
    routes: routes,
    data: function() {
        var host = "";
        if(location.host.match(/localhost/)){
            host = "https://sumapita.com";
        }else{
            host = `https://${location.host}`;
        }
        return {
            host: host,
            ec: "https://www.pitatto-ordermade.jp/",
            agreementDisabled: 'disabled',
        }
    },
    methods: {
        /**
         * 現在の日時をフォーマット
         * @return {string} 現在の日時
         */
        getNow: function() {
            var now = new Date(); // 現在の日時
            var year = String(now.getFullYear()); // 年
            var month = app.methods.set2Fig(now.getMonth() + 1); // 月
            var day = app.methods.set2Fig(now.getDate()); // 日
            return year + month + day;
        },
        /**
         * 日付の桁数が1桁の場合先頭に0を加えて2桁に調整
         * @param  {number} num 月または日
         * @return {string}     フォーマット後の月または日
         */
        set2Fig: function(num) {
            var res = num < 10 ? '0' + num : num;
            return res;
        },
        invalidURL: function() {
            var self = this;
            var errTicket = self.toast.create({
                icon: `<i class="f7-icons">xmark</i>`,
                text: "URLが無効です",
                position: 'center',
                closeTimeout: 3000,
            });
            errTicket.open();
            setTimeout(function() {
                location.href = self.data.ec;
            }, 500);
        },
        illegalURL: function() {
            var self = this;
            setTimeout(function() {
                location.href = self.data.ec;
            }, 3000);
        },
        popupGuide: function(){
            var detail = `<div></div>`;
            var guide = app.popup.create({
                swipeToClose: false,
                animate: true,
                content: `<div id="guide" class="popup" style="overflow:scroll;">
                    <div class="view">
                        <div class="page">
                            <div class="navbar">
                                <div class="navbar-bg bg-color-white blur"></div>
                                <div class="navbar-inner sliding">
                                    <div class="left">
                                        <a href="#" class="link icon-only">
                                            <i class="icon f7-icons"></i>
                                        </a>
                                    </div>
                                    <div class="title text-color-black">服装＆環境のチェック</div>
                                    <div class="right">
                                        <a href="#" class="tutorial-close link text-color-pink icon-only"><i class="icon f7-icons">multiply_circle_fill</i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="toolbar toolbar-bottom">
                                <div class="block row">
                                    <a href="#" class="tutorial-btn prev-btn col button button-large button-fill color-pink icon-only" style="min-height: 44px;"><i class="icon f7-icons">chevron_left</i></a>
                                    <a href="#" class="tutorial-btn next-btn col button button-large button-fill color-pink icon-only" style="min-height: 44px;"><i class="icon f7-icons">chevron_right</i></a>
                                </div>
                            </div>
                            <div class="page-content">
                                <div class="swiper-container swiper-init guide-swiper">
                                    <div class="swiper-pagination"></div>
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide">
                                            <img src="https://sumapita.com/images/tutorial01.jpg">
                                        </div>
                                        <div class="swiper-slide">
                                            <div class="block-title">撮影をお願いする</div>
                                            <div class="block block-strong">
                                                <strong>撮影開始後カメラが起動しない場合は、スマートフォンおよびタブレットの設定からブラウザのカメラ接続を許可してください。</strong>
                                                <br>
                                                <br>
                                                <strong>中央の十字マークの線が動かない場合は、スマートフォンおよびタブレットの設定からブラウザのモーションセンサーを有効化してください。</strong>
                                            </div>
                                            <img src="https://sumapita.com/images/tutorial02.jpg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`,
                on: {
                    opened: function(e) {
                        var swiper = this.app.swiper.create('.swiper-container', {
                            initialSlide: 0,
                            slidesPerView: 1,
                            autoHeight: true,
                            loop: true,
                            speed: 400,
                            allowTouchMove: true,
                            pagination: {
                                el: '.swiper-pagination',
                                clickable: true,
                                renderBullet: function (index, className) {
                                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                                },
                            },
                            watchOverflow: false,
                            on: {
                                slideChange: function () {
                                    // スライドを移動した場合
                                    var text = "";
                                    var btn_text = "";
                                    $$('#guide .page-content').scrollTop(0);
                                    switch (this.realIndex) {
                                        case 0:
                                            text = "服装＆環境のチェック";
                                            break;
                                        case 1:
                                            text = "撮影の流れ";
                                            break;
                                    }
                                    $$('.popup .navbar .title').text(text);
                                },
                            },
                        });

                        $$(document).on('click', '.tutorial-btn', function(e){
                            if(e.target.className.match(/prev-btn/)){
                                swiper.slidePrev();
                            }
                            if(e.target.className.match(/next-btn/)){
                                swiper.slideNext();
                            }
                            $$(this).off('click');
                        });
                        
                        $$(document).on('click', '.tutorial-close', function(e){
                            guide.close();
                            $$(this).off('click');
                        });
                    },
                }
            });
            guide.open();
        },
        openSizeList: function() {
            var self = this;
            var host = self.data.host;
            self.detail = 
            `<div class="card">
                <div class="card-content">
                    <div class="list">
                        <ul>
                            <li>
                                <div class="card-header">首回り</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/01_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">喉仏下、首に垂直な周囲</div>
                                    </div>
                                </div>
                            </li>
                                
                            <li>
                                <div class="card-header">肩幅</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/02_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">左肩先点から、第7頸椎を経由し、右肩先までの距離</div>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="card-header">二の腕周り(右)</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/03_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">肩先点と肘の突起部の中間地点の、上腕の周囲(右)</div>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="card-header">裄丈(右)</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/04_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">袖の長さ。第7頸椎から肩先点、腕の肘骨を経由し、手首の飛び出た骨までの距離(右)</div>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="card-header">腕の長さ(右)</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/05_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">肩先点から肘骨を経由し、手首の飛び出た骨までの距離(右)</div>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="card-header">バスト</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/06_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">バストの一番前に突き出している部分の、水平な周囲</div>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="card-header">ウエスト</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/07_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">肋骨の一番下と腰骨の一番上の中間地点の、水平な周囲</div>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="card-header">ヒップ</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/08_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">ヒップの最大の周囲</div>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="card-header">股下(股下から床)</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/09_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">股下点から床までの高さ</div>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div class="card-header">太もも周り(一番太い周囲、右)</div>
                                <div class="item-content">
                                    <div class="item-media">
                                        <img src="${host}/images/10_size.png">
                                    </div>
                                    <div class="item-inner">
                                        <div class="item-title">太ももの最大の周囲(右)</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>`;
            self.size = app.popup.create({
                swipeToClose: false,
                animate: true,
                content: `<div id="size" class="popup">
                    <div class="view">
                        <div class="page">
                            <div class="navbar">
                                <div class="navbar-bg bg-color-white blur"></div>
                                <div class="navbar-inner">
                                    <div class="left">
                                        <a href="#" class="link icon-only">
                                            <i class="icon f7-icons"></i>
                                        </a>
                                    </div>
                                    <div class="title text-color-black">採寸イメージ</div>
                                    <div class="right">
                                        <a href="#" class="popup-close link text-color-pink icon-only"><i class="icon f7-icons">multiply_circle_fill</i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="page-content">${self.detail}</div>
                        </div>
                    </div>
                </div>`,
                on: {
                    opened: function(e) {
                        
                    },
                }
            });
            self.size.open();
        },
        requestApiPost: function(api_name, url, data) {
            return app.request.promise.post(url, data);
        },
        openTerms: function() {
            var self = this;
            window.open('https://sumapita.com/pdf/terms.pdf', '_blank');
            /*
            // 規約の最下部までスクロール必須の場合
            var $terms = $$('#terms');
            var clientHeight = $terms.prop('clientHeight');
            var scrollHeight = $terms.prop('scrollHeight');
            $terms.scroll(function() {
                if (scrollHeight - (clientHeight + this.scrollTop) <= 0) {
                    // 最下部へ到達
                    self.agreementDisabled = '';
                    self.$update();
                }
            });
            */
        },
        openPrivacy: function() {
            var self = this;
            window.open('https://sumapita.com/pdf/policy.pdf', '_blank');
        },
    },
    on: {
        init: function() {
            app.api_url = "https://sumapita.com/api/estimationPost.php/";
            window.addEventListener("load",	function() {
                setTimeout(function() {
                    scrollTo(0,1);
                }, 100);
            }, false);

            var queryString = window.location.search;
            var queryObject = new Object();

            var order_id = '',
                code = '',
                state= '';
                
            if (queryString) {
                queryString = queryString.substring(1);
                var parameters = queryString.split('&');

                for (var i = 0; i < parameters.length; i++) {
                    var element = parameters[i].split('=');

                    var paramName = decodeURIComponent(element[0]);
                    var paramValue = decodeURIComponent(element[1]);

                    queryObject[paramName] = paramValue;
                }
                
                if (queryObject.order_id != null) {
                    order_id = queryObject.order_id;
                    app.order_id = order_id;
                    sessionStorage.setItem('order_id', order_id);
                }
                
                if (queryObject.state != null) {
                    state = queryObject.state;
                    app.state = state;
                }
                //console.log('----- ORDER_ID -----\n' + order_id);
                //console.log('----- STATE -----\n' + state);
                if (order_id == "" || state == "") {
                    app.methods.invalidURL();
                }
            } else {
                console.log(window.location.pathname);
                app.methods.illegalURL();
            }
        },
        /**
         * 必要なページのコンポーネントとナビゲーションバーを初期化後イベントがトリガーされる
         * @param  {Object} pageData ページ情報
         */
        pageInit: function(pageData) {
            // 自身のページを履歴に追加
            history.pushState(null, null, null);
            // ページ戻り時にも自身のページを履歴に追加
            $$(window).on("popstate", function(){
                history.pushState(null, null, null);
            });
        },
        /**
         * 初期化された全ページがビュー(アクティブ/現在位置)に遷移する準備が整うとイベントがトリガーされる
         * @param  {Object} pageData ページ情報
         */
        pageBeforeIn: function(pageData) {},
        /**
         * ページがビューから遷移する直前にイベントがトリガーされる
         * @param  {Object} pageData ページ情報
         */
        pageBeforeOut: function(pageData) {},
        /**
         * ページがビューに遷移した後にイベントがトリガーされる
         * @param  {Object} pageData ページ情報
         */
        pageAfterIn: function(pageData) {},
        /**
         * ページがビューから遷移した後にイベントがトリガーされる
         * @param  {Object} pageData ページ情報
         */
        pageAfterOut: function(pageData) {},
    },
});