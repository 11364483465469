import HomePage from '../pages/home.f7.html';
import FormPage from '../pages/form.f7.html';
import HowtoPage from '../pages/howto.f7.html';
import MethodPage from '../pages/method.f7.html';
import CameraPage from '../pages/camera.f7.html';
import ConfirmationPage from '../pages/confirmation.f7.html';
import CompletePage from '../pages/complete.f7.html';
//import ResultListPage from '../pages/result-list.f7.html';
import ResultPage from '../pages/result.f7.html';
import AdminPage from '../pages/admin.f7.html';

import LeftPage1 from '../pages/left-page-1.f7.html';
import LeftPage2 from '../pages/left-page-2.f7.html';
import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes = [{
        path: '/',
        options: {
            // ページ遷移時のアニメーションを指定
            // https://framework7.io/docs/view.html#custom-page-transitions
            transition: 'f7-flip',
        },
        component: HomePage,
    },
    {
        path: '/form/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: FormPage,
    },
    {
        path: '/howto/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: HowtoPage,
    },
    {
        path: '/method/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: MethodPage,
    },
    {
        path: '/camera/:state',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: CameraPage,
    },
    {
        path: '/confirmation/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: ConfirmationPage,
    },
    {
        path: '/complete/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: CompletePage,
    },
    /*
    {
        path: '/result-list/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: ResultListPage,
    },
    */
    {
        path: '/result/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: ResultPage,
    },
    {
        path: '/admin/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: AdminPage,
    },
    {
        path: '/left-page-1/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: LeftPage1,
    },
    {
        path: '/left-page-2/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: LeftPage2,
    },
    {
        path: '/dynamic-route/blog/:blogId/post/:postId/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: DynamicRoutePage,
    },
    {
        path: '/request-and-load/user/:userId/',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        async: function(routeTo, routeFrom, resolve, reject) {
            // Router instance
            var router = this;

            // App instance
            var app = router.app;

            // Show Preloader
            app.preloader.show();

            // User ID from request
            var userId = routeTo.params.userId;

            // Simulate Ajax Request
            setTimeout(function() {
                // We got user data from request
                var user = {
                    firstName: 'Vladimir',
                    lastName: 'Kharlampidi',
                    about: 'Hello, i am creator of Framework7! Hope you like it!',
                    links: [{
                            title: 'Framework7 Website',
                            url: 'http://framework7.io',
                        },
                        {
                            title: 'Framework7 Forum',
                            url: 'http://forum.framework7.io',
                        },
                    ]
                };
                // Hide Preloader
                app.preloader.hide();

                // Resolve route to load page
                resolve({
                    component: RequestAndLoad,
                }, {
                    context: {
                        user: user,
                    }
                });
            }, 1000);
        },
    },
    {
        path: '(.*)',
        options: {
            // ページ遷移時のアニメーションを指定
            transition: 'f7-circle',
        },
        component: NotFoundPage,
    },
];

export default routes;