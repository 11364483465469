import $$ from 'dom7';

var UAParser = require('ua-parser-js');

const MAX_HEIGHT = 220.0;
const MEDIA_CONSTRAINT_REAR = {
  video: {
    height: 1080,
    width: 1920,
    facingMode: {
      exact: "environment"
    } // chromeテスト時
    //facingMode: 'user',

  },
  audio: false
};
const MEDIA_CONSTRAINT_FRONT = {
  video: {
    height: 1080,
    width: 1920,
    facingMode: 'user'
  },
  audio: false
};
export default {
  data: function () {
    var host = this.$app.data.host;
    return {
      host: host,
      frontGuideImg: `${host}/images/front.svg`,
      sideGuideImg: `${host}/images/side.svg`,
      circleOrange: `${host}/images/circle_or.svg`,
      circleGreen: `${host}/images/circle_gr.svg`,
      checkMark: `${host}/images/check.svg`,
      iconWhite: `${host}/images/measuring_icon.svg`,
      iconBlack: `${host}/images/measuring_icon_bk.svg`,
      storage: undefined,
      sensorDirection: 1,
      vIndicator: undefined,
      hIndicator: undefined,
      cIndicator: undefined,
      clientWidth: 0,
      clientHeight: 0,
      yawAngle: 0,
      pitchAngle: 0,
      cameraView: undefined,
      cameraSensor: undefined,
      cameraTrigger: undefined,
      cameraTriggerImage: undefined,
      frontGuide: undefined,
      sideGuide: undefined,
      guideScale: 1.0,
      isCapturingFront: true,
      canCapture: false,
      cameraTitle: undefined,
      intervalId: undefined
    };
  },
  methods: {
    openCamera: function () {
      if (DeviceMotionEvent && DeviceMotionEvent.requestPermission && typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission().then(function (response) {
          if (response == 'granted') {
            window.addEventListener('devicemotion', function (e) {// do something with e
              //console.log(e);
            });
          }
        }).catch(console.error);
      }

      if (DeviceOrientationEvent && DeviceOrientationEvent.requestPermission && typeof DeviceOrientationEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission().then(function (response) {
          if (response == 'granted') {
            window.addEventListener('deviceorientation', function (e) {// do something with e
              //console.log(e);
            });
          }
        }).catch(console.error);
      }
    },
    initializeData: function (state) {
      var bodyData = JSON.parse(sessionStorage.getItem('bodyData'));
      this.guideScale = Math.min(parseFloat(bodyData.height / 10) / MAX_HEIGHT, 1.0); // OS

      this.sensorDirection = new UAParser().getOS().name == 'iOS' ? 1 : -1; // Use camera area size for indicators' movement

      this.clientWidth = document.getElementById('camera-container').clientWidth;
      this.clientHeight = document.getElementById('camera-container').clientHeight; // Human-shape guideline

      this.frontGuide = document.getElementById('guideline-front');
      this.sideGuide = document.getElementById('guideline-side'); // Indicators which shows device's tilt

      this.vIndicator = document.getElementById('v-indicator');
      this.hIndicator = document.getElementById('h-indicator');
      this.cIndicator = document.getElementById('c-indicator'); // Circle

      this.circle = document.getElementById("#circle"); // Camera parts

      this.cameraView = document.getElementById("camera--view"); // video

      this.cameraSensor = document.getElementById("camera--sensor"); // canvas

      this.cameraTrigger = document.getElementById("camera--trigger"); // capture button

      this.cameraTitle = '測定'; // Capture implementation

      this.cameraTrigger.onclick = () => {
        this.capture();
      }; // Centering indicators


      this.vIndicator.style.left = `${this.clientWidth / 2}px`;
      this.hIndicator.style.top = `${this.clientHeight / 2}px`;
      this.cIndicator.style.top = `${this.clientHeight / 2}px`;
      this.cIndicator.style.left = `${this.clientWidth / 2}px`;
      this.$update();
    },
    updateCaptureTarget: function (isFirstLoad = false) {
      let isCapturingFront = false;
      const frontImage = sessionStorage.getItem('bgFrontImageURI');
      const sideImage = sessionStorage.getItem('bgSideImageURI');
      const isBothStored = frontImage && sideImage;
      const isFrontStored = frontImage && !sideImage;
      const isSideStored = !frontImage && sideImage;
      const isBothEmpty = !frontImage && !sideImage;

      if (isBothStored && isFirstLoad) {
        // Opening this page with both images stored is not supposed, so delete both.
        sessionStorage.removeItem('bgFrontImageURI');
        sessionStorage.removeItem('bgSideImageURI');
        isCapturingFront = true;
      }

      if (isFrontStored) isCapturingFront = false;
      if (isSideStored) isCapturingFront = true;
      if (isBothEmpty) isCapturingFront = true;
      return isCapturingFront;
    },
    startListeningGyro: function () {
      window.addEventListener('deviceorientation', this.calcAngle, true);
      this.intervalId = setInterval(this.handleIndicator, 5);
    },
    startCamera: function (mode) {
      switch (mode) {
        case "rear":
          mode = MEDIA_CONSTRAINT_REAR;
          break;

        case "front":
          mode = MEDIA_CONSTRAINT_FRONT;
          break;
      }

      new Promise((onSuccess, onFailed) => {
        // getUserMediaでは広角レンズが選択されてしまう場合があるので(Xperia5等)番号の若いカメラを選択するようにする
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
          const device = devices.filter(device => device.kind == 'videoinput').sort(function (a, b) {
            return a.label == b.label ? 0 : a.label > b.label ? 1 : -1;
          }).filter(device => device.label.includes('back')).shift();
          onSuccess(device ? {
            video: {
              deviceId: device.deviceId,
              height: 1080,
              width: 1920
            },
            audio: false
          } : mode);
        }).catch(function (err) {
          //console.log(err.name + ": " + err.message)
          onFailed(err);
        });
      }).then(constraint => navigator.mediaDevices.getUserMedia(constraint)).then(stream => {
        this.setupCameraView(stream);
        this.scaleGuide();
      }).catch(error => {
        console.error("Failed on getUserMedia.", error);
        this.startCamera('front');
      });
    },
    calcAngle: function (event) {
      const _x = event.beta ? event.beta * Math.PI / 180 : 0;

      const _y = event.gamma ? event.gamma * Math.PI / 180 : 0;

      const cX = Math.cos(_x);
      const cY = Math.cos(_y);
      const sY = Math.sin(_y);
      const m31 = -cX * sY;
      const m33 = cX * cY;
      this.pitchAngle = -m33 * 90;
      this.yawAngle = m31 * 90;
    },
    handleIndicator: function () {
      let yaw = this.yawAngle / 180; // -1 < yaw < 1

      let pitch = this.pitchAngle / 90; // -1 <= pitch <= 1

      yaw = Math.max(Math.min(1, yaw), -1);
      pitch = Math.max(Math.min(1, pitch), -1);
      let vLeft = this.clientWidth * (Math.sin(yaw * Math.PI / 2) + 0.5);
      let hTop = this.clientHeight * (pitch + 1) / 2;
      let hTopC = this.clientHeight * ((Math.sin(pitch / 2 * Math.PI) + 1) / 2); // デバイスの傾きを示す各インジケーター要素を格納

      this.vIndicator = document.getElementById("v-indicator");
      this.hIndicator = document.getElementById("h-indicator");
      this.cIndicator = document.getElementById("c-indicator"); // 撮影ボタン

      this.cameraTrigger = document.getElementById("camera--trigger"); // 撮影ボタン枠

      this.cameraBtnContainer = document.getElementById("camera-button-container"); // 撮影ボタン画像

      this.cameraTriggerImage = document.getElementById("camera--trigger--image"); // 中央サークル

      this.circle = document.getElementById("circle"); // チェックマーク

      this.check = document.getElementById("check-mark"); // 各インジケーターに数値を反映

      this.vIndicator.style.left = `${vLeft}px`;
      this.hIndicator.style.transform = `rotate(${this.yawAngle}deg)`;
      this.hIndicator.style.top = `${hTop}px`;
      this.cIndicator.style.left = `${vLeft}px`;
      this.cIndicator.style.top = `${hTopC}px`; // 垂直状態

      const isYawCenter = Math.abs(this.yawAngle) < 3; // 水平状態

      const isPitchCenter = Math.abs(this.pitchAngle) < 3; // 撮影可能

      this.canCapture = isYawCenter && isPitchCenter; // カラーセット

      let indiWhite = "rgba(255,255,255, .6)";
      let indiRed = "rgba(255,45,85, .6)"; // 垂直状態 : 非垂直状態

      this.vIndicator.style.backgroundColor = isYawCenter ? indiWhite : indiRed; // 水平状態 : 非水平状態

      this.hIndicator.style.backgroundColor = isPitchCenter ? indiWhite : indiRed; // 撮影可能状態 :　非撮影可能状態

      this.circle.setAttribute('src', this.canCapture ? this.circleGreen : this.circleOrange);
      this.check.style.opacity = this.canCapture ? 1 : 0.1;
      this.cameraTrigger.style.background = this.canCapture ? "rgba(255, 255, 255, .8)" : "rgba(255, 255, 255, .5)";
      this.cameraBtnContainer.style.borderColor = this.canCapture ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, .5)";
      this.cameraTriggerImage.setAttribute('src', this.canCapture ? this.iconBlack : this.iconWhite);
      this.vIndicator.style.height = this.canCapture ? "50vw" : "100%";
      this.vIndicator.style.top = this.canCapture ? "calc(50% - 50vw / 2)" : "0";
      this.hIndicator.style.width = this.canCapture ? "50vw" : "200%";
      this.hIndicator.style.left = this.canCapture ? "calc(50vw / 2)" : "-50%";
    },
    capture: function () {
      var self = this;
      return new Promise((onSuccess, onFailed) => {
        if (this.canCapture) {
          this.cameraSensor.width = this.cameraView.videoWidth;
          this.cameraSensor.height = this.cameraView.videoHeight;
          const ctx = this.cameraSensor.getContext("2d");
          ctx.save();
          ctx.drawImage(this.cameraView, 0, 0);
          this.setImage2Storage(ctx, this).then(() => {
            this.toggleGuideline();
            self.toastSuccess = self.$app.toast.create({
              icon: `<i class="f7-icons">smiley</i>`,
              text: '撮影成功',
              position: 'center',
              closeTimeout: 1500
            });
            self.toastSuccess.open();
            setTimeout(function () {
              self.cameraTrigger.style.pointerEvents = "none";
            }, 0);
            setTimeout(function () {
              self.cameraTrigger.style.pointerEvents = "auto";
            }, 1500);
            onSuccess();
          });
        } else {
          onFailed();
        }
      });
    },
    setupCameraView: function (stream) {
      this.cameraView.srcObject = stream; //this.cameraView.style.transform = 'scaleX(1)';
    },
    scaleGuide: function () {
      var self = this;
      this.cameraView.addEventListener('loadedmetadata', function () {
        var guideHeight = self.guideScale * self.cameraView.clientHeight;
        self.frontGuide.style.height = `${guideHeight}px`;
        self.sideGuide.style.height = `${guideHeight}px`;
        self.frontGuide.style.opacity = 1;
      });
    },
    setImage2Storage: function (canvasCtx, self) {
      return new Promise((onSuccess, onFailed) => {
        this.cameraSensor.toBlob(function (blob) {
          const blobUrl = URL.createObjectURL(blob);
          self.clearCanvas(canvasCtx);

          if (self.isCapturingFront) {
            sessionStorage.setItem('bgFrontImageURI', blobUrl);
          } else {
            sessionStorage.setItem('bgSideImageURI', blobUrl);
          }

          if (sessionStorage.getItem('bgFrontImageURI') && sessionStorage.getItem('bgSideImageURI')) {
            self.stopTracks().then(() => {
              //console.log('----- GET SIDE IMAGE -----');
              self.$router.navigate('/confirmation/');
            });
          }

          onSuccess();
        }, "image/jpeg", 0.9);
      });
    },
    stopTracks: function () {
      return new Promise((onSuccess, onFailed) => {
        if (this.cameraView.srcObject) {
          this.cameraView.srcObject.getVideoTracks().forEach(track => {
            track.stop();
          });
          this.cameraView.srcObject.getAudioTracks().forEach(track => {
            track.stop();
          });
        }

        onSuccess();
      });
    },
    clearCanvas: function (canvasCtx) {
      canvasCtx.clearRect(0, 0, this.cameraSensor.width, this.cameraSensor.height);
    },
    toggleGuideline: function () {
      this.isCapturingFront = this.updateCaptureTarget();

      if (this.isCapturingFront) {
        this.frontGuide.style.display = 'block';
        this.sideGuide.style.display = 'none';
      } else {
        this.frontGuide.style.display = 'none';
        this.sideGuide.style.display = 'block';
      }
    }
  },
  on: {
    pageMounted: function (e, page) {
      const isFirstLoad = true;
      this.initializeData(this.$route.params.state);
      this.isCapturingFront = this.updateCaptureTarget(isFirstLoad);
      this.startListeningGyro();
      this.startCamera('rear');
    },
    pageInit: function (e, page) {
      sessionStorage.setItem('openTutorial', 'opened');
      const frontImageURI = sessionStorage.getItem('bgFrontImageURI');
      const sideImageURI = sessionStorage.getItem('bgSideImageURI');
      if (frontImageURI) URL.revokeObjectURL(frontImageURI);
      if (sideImageURI) URL.revokeObjectURL(sideImageURI);
      sessionStorage.removeItem('bgFrontImageURI');
      sessionStorage.removeItem('bgSideImageURI');
      this.openCamera();
    },
    pageAfterOut: function (e, page) {
      // インターバルクリア
      clearInterval(this.intervalId);
    }
  },
  id: '130e0d35a6',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=guide><div class=navbar><div class="navbar-bg bg-color-white blur"></div><div class="navbar-inner sliding"><div class=left><a href=/form/ class="link text-color-pink" @click=stopTracks><i class="icon f7-icons">arrow_left</i> 戻る</a></div><div class="title text-color-black">';
      r += c(ctx_1.cameraTitle, ctx_1);
      r += '</div><div class=right><a href=# class="link text-color-pink icon-only" @click=$app.methods.popupGuide><i class="icon f7-icons">question_diamond_fill</i></a></div></div></div><div class=page-content><div id=camera-container><!-- Camera view --><video id=camera--view autoplay playsinline></video><img src=';
      r += c(ctx_1.frontGuideImg, ctx_1);
      r += ' id=guideline-front> <img src=';
      r += c(ctx_1.sideGuideImg, ctx_1);
      r += ' id=guideline-side><!-- Camera sensor --><canvas id=camera--sensor></canvas><!-- Indicators --><div id=v-refference></div><div id=h-refference></div><div id=v-indicator class=indicator></div><div id=h-indicator class=indicator></div><div id=c-indicator></div><!-- Circle --> <img src=';
      r += c(ctx_1.circleOrange, ctx_1);
      r += ' id=circle> <img src=';
      r += c(ctx_1.checkMark, ctx_1);
      r += ' id=check-mark><!-- Camera trigger --><div id=camera-button-container><button id=camera--trigger title="take a picture"><img id=camera--trigger--image src=';
      r += c(ctx_1.iconWhite, ctx_1);
      r += '></button></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};