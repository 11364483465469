import $$ from 'dom7';
export default {
  methods: {
    openCamera: function () {
      if (DeviceMotionEvent && DeviceMotionEvent.requestPermission && typeof DeviceMotionEvent.requestPermission === 'function') {
        DeviceMotionEvent.requestPermission().then(function (response) {
          if (response == 'granted') {
            window.addEventListener('devicemotion', function (e) {
              // do something with e
              console.log(e);
            });
          }
        }).catch(console.error);
      }

      if (DeviceOrientationEvent && DeviceOrientationEvent.requestPermission && typeof DeviceOrientationEvent.requestPermission === 'function') {
        DeviceOrientationEvent.requestPermission().then(function (response) {
          if (response == 'granted') {
            window.addEventListener('deviceorientation', function (e) {
              // do something with e
              console.log(e);
            });
          }
        }).catch(console.error);
      }

      this.$app.views.current.router.navigate('/camera/');
    }
  },
  on: {
    pageAfterIn: function (e, page) {
      this.openCamera();
    }
  },
  id: 'd970f44b5e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=guide><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=/form/ class=link data-panel=right>戻る</a></div><div class=title>撮影方法</div><div class=right><a href=# class="link icon-only" data-panel=right @click=$app.methods.popupGuide><i class="icon f7-icons">question_diamond_fill</i></a></div></div></div><div class="page-content camera"><div class="card elevation-2"><a href=/camera/selfie @click=openCamera><div class=card-header>自分で撮影する</div><div class="card-content card-content-padding"><img src=static/images/self.png></div></a></div><div class="card elevation-2"><a href=/camera/default @click=openCamera><div class=card-header>他の人を撮影する</div><div class="card-content card-content-padding"><img src=static/images/others.png></div></a></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};