import $$ from 'dom7';
export default {
  data: function () {
    return {};
  },
  methods: {
    test: function (data) {
      return 'test';
    }
  },
  on: {
    pageAfterIn: function (e, page) {}
  },
  id: '42c7732956',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=form><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=/ class=link data-panel=right>戻る</a></div><div class=title>身体情報の入力</div><div class=right><a href=# class="link icon-only" data-panel=right @click=$app.methods.popupGuide><i class="icon f7-icons">question_diamond_fill</i></a></div></div></div><div class="toolbar toolbar-bottom"><div class=block><a href=# id=save-form class="button button-large button-fill disabled" @click=saveForm>次へ</a></div></div><div class=page-content><form id=body-data-form><div class="list no-hairlines"><ul><li><div class="item-content item-input item-input-outline"><div class=item-inner><div class="item-title item-floating-label">身 長</div><div class=item-input-wrap><input type=text name=height readonly=readonly id=picker-describe-height></div></div></div></li><li><div class="item-content item-input item-input-outline"><div class=item-inner><div class="item-title item-floating-label">体 重</div><div class="item-input-wrap row"><input type=text name=weight readonly=readonly id=picker-describe-weight></div></div></div></li><li class="item-content item-input item-input-outline"><div class=item-inner><div class="item-title item-floating-label">年 齢</div><div class=item-input-wrap><input type=text name=age readonly=readonly id=picker-describe-age></div></div></li></ul></div><div class="block row"><label class="col radio"><input type=radio name=gender value=male required validate> <i class=icon-radio></i><span>男性</span></label> <label class="col radio"><input type=radio name=gender value=female required validate> <i class=icon-radio></i><span>女性</span></label></div></form></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};